// Must be set explicitly or there will be errors
window.adminFunc = null;

// eslint-disable-next-line no-redeclare,no-unused-vars
function admin() {
  config = localStorage.getItem('configuration');
  if (config === '1' || config === '0') config = null;

  // eslint-disable-next-line no-unused-vars
  window.systemVars = [
    'id',
    'short',
    'warning',
    'length',
    'user',
    'store',
    'hidden',
    'read',
    'hidden',
    'where',
    'button',
    'autoMail',
    'tableLabels',
    'webhook',
    'list',
    'filterGroup',
    'amount',
    'loading',
  ];
  // eslint-disable-next-line no-unused-vars
  window.globalOptions = ['whereForm', 'apiFields', 'save'];

  window.adminFunc = localStorage.getItem(client + 'AdminFunc');
  build();
}
